import React, { useState } from "react";
import { ProcessInstance } from "../../api/Model";
import { ButtonGroupContainer, ImageContainer } from "../Containers";
import { SummaryContent } from "./SummaryContent";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { ReadonlyLinkDialog } from "../Dialog/ReadonlyLinkDialog";
import { LandingImage } from "../../pages/public/LandingPage";
import referenzprozess from "../../images/referenzprozess.png"
import { Legend } from "./FunctionHeader";
import {
    Icon,
    styled,
    Typography
} from "@material-ui/core";
import { ButtonLGPrimary, ButtonLGSecondary } from "../Styleguide/Button";

interface Props {
    instance: ProcessInstance
    editable: boolean
}

export function Summary({ instance, editable }: Props) {
    const navigate = useNavigate();
    const snackbar = useSnackbar()
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const readonlyLink = `${window.location.origin}/sharing/${instance.readonlyId}`

    return <>
        <PrintTitle>
            Umsetzungsempfehlung
        </PrintTitle>
        <NoPrintContainer>
            <Typography variant={"h1"}>Übersicht & Empfehlungen Umsetzung</Typography>
        </NoPrintContainer>
        <ImageContainer>
            <LandingImage width="100%" src={referenzprozess} alt="Referenzprozess" style={{ paddingBottom: "15px" }}/>
            <Typography variant={"caption"}>Diagram Referenzprozess</Typography>
        </ImageContainer>
        <Legend inSelection={false}/>
        <SummaryContent snapshot={instance.snapshot}
                        selectedFunctions={instance.selectedFunctions}
                        comments={instance.comments}/>
        <ReadonlyLinkDialog open={openConfirmationDialog} setOpen={setOpenConfirmationDialog} readonlyLink={readonlyLink}/>
        <ButtonGroupContainer style={{ paddingBottom: "30px" }}>
            <ButtonDiv>
                {editable && <ButtonLGPrimary onClick={() => navigate(`/instances/${ instance.id }/functions/4`)}>Zurück</ButtonLGPrimary>}
            </ButtonDiv>
            <ButtonDiv>
                <ButtonWithMargin variant="contained" startIcon={<Icon>content_copy</Icon>}
                        onClick={() => {
                            if (navigator.clipboard) {
                                navigator.clipboard.writeText(readonlyLink)
                                    .then(() => {
                                        snackbar.enqueueSnackbar("Link in die Zwischenablage kopiert", {variant: "success"})
                                    })
                            } else {
                                setOpenConfirmationDialog(true);
                            }
                        }}>
                    Teilen
                </ButtonWithMargin>
                <ButtonLGPrimary onClick={() => window.print()}>PDF erstellen</ButtonLGPrimary>
            </ButtonDiv>
        </ButtonGroupContainer>
    </>
}

const ButtonWithMargin = styled(ButtonLGSecondary)({
  marginRight: '1rem'
})


const PrintTitle = styled('h1')({
    display: "none",
    '@media print': {
        display: "block",
    }
})

const NoPrintContainer = styled('div')({
    '@media print': {
        display: "none",
    }
})

const ButtonDiv = styled('div')({
    marginRight: "0 !important",
    marginLeft: "0 !important",
})