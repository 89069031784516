import React, { useMemo, useState } from "react";
import { Button, Grid, Box, Typography } from "@material-ui/core";
import { FunctionData } from "../../components/Admin/FunctionData";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import { BusinessFunction } from "../../api/Model";
import { ValidationErrors } from "final-form";
import arrayMutators from "final-form-arrays";
import { defaultErrorHandler, useBusinessFunction, useFunctionApi } from "../../api/AdminHooks";
import { makeStyles } from "@material-ui/core/styles";
import { routes } from "../../utils/routes";

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        '& > *': {
            margin: theme.spacing(1)
        }
    }
}))

export function CreateFunction() {
    const navigate = useNavigate()
    const {create} = useFunctionApi()
    const {step} = useParams<{ step: string }>()

    const createFunction = (fun: Partial<BusinessFunction>) => {
        create(fun)
            .then(fun => {
                window.history.replaceState(null, "", routes.admin.functions.edit(fun.id))
                navigate(routes.admin.functions.list(fun.step));
            })
            .catch(defaultErrorHandler)
    }

    const initialValues = useMemo(() => ({
        step: Number(step),
        customer: {enabled: true, egovDomain: true, name: "", description: "", recommendation: ""},
        administration: {enabled: true, egovDomain: true, name: "", description: "", recommendation: ""}
    }), [step])

    return <>
        <Box paddingY={2}>
            <Typography variant="h2">
                Fachliche Funktion erfassen
            </Typography>
        </Box>
        <EditFunctionInternal submit={createFunction} initialValues={initialValues}/>
    </>
}

export function EditFunction() {
    const {id} = useParams<{ id: string }>()
    const {businessFunction} = useBusinessFunction(id!!)
    if (businessFunction === undefined) {
        return null;
    }
    return <EditFunctionInner businessFunction={businessFunction}/>
}

function EditFunctionInner(props: {businessFunction: BusinessFunction}) {
    const navigate = useNavigate()
    const {update} = useFunctionApi()
    const [initialValues] = useState(props.businessFunction)

    const updateFunction = (fun: Partial<BusinessFunction>) => {
        update(fun)
            .then(fun => {
                navigate(routes.admin.functions.list(fun.step));
            })
            .catch(defaultErrorHandler)
    }

    return <>
        <Box paddingY={2}>
            <Typography variant="h2">
                Fachliche Funktion erfassen
            </Typography>
        </Box>
        <EditFunctionInternal submit={updateFunction} initialValues={initialValues}/>
    </>
}

export function EditFunctionInternal(props: { initialValues: Partial<BusinessFunction>, submit: (fun: Partial<BusinessFunction>) => void }) {
    const navigate = useNavigate()
    const classes = useStyles()

    const abort = () => {
        navigate(routes.admin.functions.list(props.initialValues.step));
    }

    return <>
        <Form onSubmit={props.submit}
              mutators={{...arrayMutators}}
              validate={(values: Partial<BusinessFunction>) => {
                  const errors: ValidationErrors = {
                      administration: {},
                      customer: {},
                  }
                  if (!values?.administration?.enabled && !values?.customer?.enabled) {
                      errors.administration.enabled = 'Entweder Kunde oder Verwaltung muss aktiv sein'
                      errors.customer.enabled = 'Entweder Kunde oder Verwaltung muss aktiv sein'
                  }
                  return errors
              }}
              initialValues={props.initialValues}
              render={({
                           valid,
                           pristine,
                           handleSubmit,
                           form: {
                               mutators: {push}
                           }
                       }) => (
                  <form onSubmit={handleSubmit}>
                      <Grid container spacing={4}>
                          <Grid item xs={6}>
                              <h2>Kundin/Kunde</h2>
                              <FunctionData name="customer"/>
                          </Grid>
                          <Grid item xs={6}>
                              <h2>Verwaltung</h2>
                              <FunctionData name="administration"/>
                          </Grid>

                          <Grid item container justifyContent="flex-end" className={classes.buttonContainer}>
                              <Button variant="outlined" type="button" onClick={abort}>
                                  Zurück
                              </Button>
                              <Button color="primary" variant="outlined" type="submit" disabled={pristine || !valid}>
                                  Speichern
                              </Button>
                          </Grid>
                      </Grid>
                  </form>
              )}
        />
    </>
}
