import { styled } from "@material-ui/core";

export const TitleH1 = styled('h1')(({theme}) => ({
    color: theme.palette.white100.main,
    fontWeight: 900,
    fontSize: "28px",
    lineHeight: "34px",
    margin: 0,
    [`@media (min-width:${theme.breakpoints.values.xs}px)`]: {
        fontSize: "32px",
        lineHeight: "38px",
    },
    [`@media (min-width:${theme.breakpoints.values.sm}px)`]: {
        fontSize: "40px",
        lineHeight: "48px",
    },
    [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
        marginTop: "15px",
        fontSize: "48px",
        lineHeight: "48px",
    },
    [`@media (min-width:${theme.breakpoints.values.lg}px)`]: {
        marginTop: "20px",
        fontSize: "56px",
        lineHeight: "56px",
    },
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        marginTop: "25px",
        fontSize: "72px",
        lineHeight: "72px",
    }
}))
export const TitleBackText = styled('p')(({theme}) => ({
    color: theme.palette.white100.main,
    margin: 0,
    "&:hover": {
        color: theme.palette.white70.main,
    },
    fontWeight: 400,
    [`@media (min-width:${theme.breakpoints.values.xxs}px)`]: {
        lineHeight: "24px",
        fontSize: "16px",
    },
    [`@media (min-width:${theme.breakpoints.values.xs}px)`]: {
        lineHeight: "26px",
        fontSize: "18px",
    },
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        lineHeight: "30px",
        fontSize: "20px",
    },
}))