import React from "react";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useLoginActions, useLoginState } from "../../context/LoginProvider";
import { FORM_ERROR } from "final-form";

export function Login() {
    const loginActions = useLoginActions()
    const {loggedIn} = useLoginState()

    if (loggedIn === undefined || loggedIn) {
        return null
    }
    return <Dialog open disableBackdropClick disableEscapeKeyDown disableEnforceFocus>
        <Form
            onSubmit={values => {
                return loginActions.login(values.username, values.password)
                    .then(success => {
                        if (!success) {
                            return {[FORM_ERROR]: 'Login fehlgeschlagen'}
                        }
                    })
            }}
            render={({submitError, submitFailed, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <TextField autoComplete="username"
                                   variant="standard"
                                   helperText={submitError}
                                   error={submitFailed}
                                   name="username"
                                   label="Benutzername"/>
                        <TextField autoComplete="password"
                                   variant="standard"
                                   helperText={submitError}
                                   error={submitFailed}
                                   name="password" label="Passwort"
                                   type="password"/>
                    </DialogContent>
                    <DialogActions>
                        <Box marginLeft="auto">
                            <Button color="primary" variant="contained" type="submit">Anmelden</Button>
                        </Box>
                    </DialogActions>
                </form>
            )}/>
    </Dialog>
}