import { ProcessInstance } from "./Model";
import { PatchInstance } from "./PublicHooks";

export function patchInstance(instance: ProcessInstance, patch: Partial<PatchInstance>) {
    const patched = {...instance}
    if (patch.selectedFunctions) {
        const added = Object.entries(patch.selectedFunctions).filter(([_, patchue]) => patchue).map(([id, _]) => id)
        const removed = Object.entries(patch.selectedFunctions).filter(([_, patchue]) => !patchue).map(([id, _]) => id)
        patched.selectedFunctions = [...patched.selectedFunctions.filter(id => !removed.includes(id)), ...added]
    }
    if (patch.comments) {
        patched.comments = {...patched.comments, ...patch.comments}
    }
    return patched
}