import { checkboxWidth } from "../../utils/layout";
import React, { useState } from "react";
import { BusinessFunction, Snapshot } from "../../api/Model";
import { PatchInstance } from "../../api/PublicHooks";
import {
    Box,
    Checkbox,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TableRow, Theme, useTheme
} from "@material-ui/core";
import { PrimaryIconRound } from "../Styleguide/Icon";

interface Props {
    snapshot: Snapshot,
    selectedFunctions: string[],
    patch: (val: Partial<PatchInstance>) => void,
    currentStep: number;
}
export function DesktopTable({snapshot, selectedFunctions, patch, currentStep}: Props) {
    const theme = useTheme();

    snapshot.process.functions.sort((a, b) => a.step < b.step ? -1 : a.step > b.step ? 1 : 0)

    return (
        <TableContainer>
            <Table style={{tableLayout: "fixed", overflow: "hidden", borderCollapse: "separate"}}>
                <colgroup>
                    <col width={checkboxWidth}/>
                    <col width="50%"/>
                    <col width={checkboxWidth}/>
                    <col width="50%"/>
                    <col width={checkboxWidth}/>
                </colgroup>
                <TableHead>
                    <HeaderRow>
                        <TableCell padding="checkbox"/>
                        <HeaderCell color={theme.palette.customer.main} colSpan={2}>
                            Kundin/Kunde
                        </HeaderCell>
                        <HeaderCell color={theme.palette.administration.main} colSpan={2}>
                            Verwaltung
                        </HeaderCell>
                    </HeaderRow>
                </TableHead>
                <TableBody>
                    {snapshot.process.functions.map((fun, i) => (
                        <Row visible={fun.step === currentStep} key={fun.id}
                                   checked={selectedFunctions.includes(fun.id)}
                                   toggle={() => patch({selectedFunctions: {[fun.id]: !selectedFunctions.includes(fun.id)}})}
                                   fun={fun} isEven={i % 2 === 0}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>)
}

function Row(props: { fun: BusinessFunction, visible: boolean, checked: boolean, toggle: () => void, isEven: boolean }) {
    const [details, setDetails] = useState(false)

    const customerDescription = props.fun.customer.enabled ? props.fun.customer.description : undefined
    const administrationDescription = props.fun.administration.enabled ? props.fun.administration.description : undefined

    if (!props.visible) {
        return null;
    }
    return <>
        <BodyRow hover={!details} onClick={props.toggle} isEven={props.isEven}>
            <StyledTableCell checked={props.checked} isLeft={true} isRight={false} padding="checkbox">
                <StyledCheckbox data-cy={`checkbox-${props.fun.id}`} color="primary" checked={props.checked}/>
            </StyledTableCell>
            <StyledTableCell checked={props.checked} isLeft={false} isRight={false}>
                {props.fun.customer.enabled && (
                    <Box display="flex">
                        <TextSpan>
                            {props.fun.customer.name}
                        </TextSpan>
                    </Box>
                )}
                {props.fun.customer.enabled && details &&
                    <Box style={{ paddingTop: "15px" }}>
                        <TextSpan>
                            {customerDescription}
                        </TextSpan>
                    </Box>}
            </StyledTableCell>
            <StyledTableCell checked={props.checked} isLeft={false} isRight={false} padding="checkbox">
                {props.fun.customer.enabled &&
                    <StyledIconButton onClick={e => {
                        e.stopPropagation()
                        setDetails(old => !old)
                    }}
                                disabled={!customerDescription && !administrationDescription}>
                        <PrimaryIconRound>{details ? "close" : "info"}</PrimaryIconRound>
                    </StyledIconButton>}
            </StyledTableCell>
            <StyledTableCell checked={props.checked} isLeft={false} isRight={false}>
                {props.fun.administration.enabled && (
                    <Box display="flex">
                        <TextSpan>
                            {props.fun.administration.name}
                        </TextSpan>
                    </Box>
                )}
                {props.fun.administration.enabled && details &&
                    <Box style={{ paddingTop: "15px" }}>
                        <TextSpan>
                            {administrationDescription}
                        </TextSpan>
                    </Box>}
            </StyledTableCell>
            <StyledTableCell checked={props.checked} isLeft={false} isRight={true} padding="checkbox">
                {props.fun.administration.enabled &&
                    <StyledIconButton onClick={e => {
                        e.stopPropagation()
                        setDetails(old => !old)
                    }}
                                disabled={!customerDescription && !administrationDescription}>
                        <PrimaryIconRound>{details ? "close" : "info"}</PrimaryIconRound>
                    </StyledIconButton>}
            </StyledTableCell>
        </BodyRow>
    </>
}

const StyledIconButton = styled(IconButton)({
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 18,
    paddingRight: 18,
})

const StyledCheckbox = styled(Checkbox)({
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 18,
    paddingRight: 18,
})

const StyledTableCell = styled(({ checked, isLeft, isRight, ...rest}) => <TableCell {...rest}/>)(({checked, isLeft, isRight}: { checked: boolean, isLeft: boolean, isRight: boolean, }) => ({
    borderRadius: "1px",
    borderTop: checked ? "2px solid black !important" : "2px solid rgba(0, 0, 0, 0) !important",
    borderBottom: checked ? "2px solid black !important" : "2px solid rgba(0, 0, 0, 0) !important",
    borderLeft: (checked && isLeft) ? "2px solid black !important" : "none",
    borderRight: (checked && isRight) ? "2px solid black !important" : "none",
    verticalAlign: "top",
}))

const BodyRow = styled(({checked, border, ...rest}) => <TableRow {...rest}/>)(({isEven}: { isEven: boolean }) => ({
    backgroundColor: isEven ? "#F0F0F0" : undefined,
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
    border: "2px solid red !important",
}))

const HeaderRow = styled(TableRow)({
    '& th': {
        border: "none",
        padding: "6px",
    },
})

const HeaderCell = styled(TableCell)(({color, theme}: { color: string, theme: Theme }) => ({
    border: "none",
    padding: "6px",
    backgroundColor: color,
    textAlign: "center",

    color: "#000000",
    fontWeight: 900,
    fontSize: "18px",
    lineHeight: "24px",
    [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
        fontSize: "20px",
        lineHeight: "26px",
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        fontSize: "24px",
        lineHeight: "30px",
    },
}))

const TextSpan = styled('span')({
    wordBreak: "break-word",
    flexGrow: 1,

    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#666666",
    "@media (min-width: 400px)": {
        fontSize: "18px",
        lineHeight: "26px",
    },
    "@media (min-width: 1280px)": {
        fontSize: "20px",
        lineHeight: "30px",
    },
})
