import React, { Dispatch, SetStateAction, useRef } from "react";
import { useSnackbar } from "notistack";
import { LinkTextField } from "./PersonalLinkDialog";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Icon
} from "@material-ui/core";

interface ReadonlyLinkDialog {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    readonlyLink: string,
}

export function ReadonlyLinkDialog(props: ReadonlyLinkDialog) {
    const snackbar = useSnackbar()
    const closeDialog = () => props.setOpen(false);
    const linkRef = useRef<HTMLInputElement>(null)
    return <Dialog open={props.open}>
        <DialogTitle>Persönlicher Link zum Projekt</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Mit folgendem Link können Sie den letzten Stand Ihrer Eingaben mit anderen Personen teilen.
            </DialogContentText>
            <Box display="flex" marginBottom={3}>
                <LinkTextField ref={linkRef} onFocus={e => e.target.select()} readOnly
                               value={props.readonlyLink}/>
                <Button variant="contained" startIcon={<Icon>content_copy</Icon>}
                        onClick={() => {
                            if (navigator.clipboard) {
                                navigator.clipboard.writeText(props.readonlyLink)
                                    .then(() => {
                                        snackbar.enqueueSnackbar("Link in die Zwischenablage kopiert", {variant: "success"})
                                    })
                            } else {
                                linkRef.current?.select()
                                if (document.execCommand("copy")) {
                                    snackbar.enqueueSnackbar("Link in die Zwischenablage kopiert", {variant: "success"})
                                }
                            }
                        }}>
                    Kopieren
                </Button>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={closeDialog} color="primary" variant="contained">Schliessen</Button>
        </DialogActions>
    </Dialog>
}
