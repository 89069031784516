import React, { useContext } from "react";
import { useSnapshotInfoApi } from "../api/AdminHooks";

interface SnapshotInfo {
    latestSnapshotDate?: string,
    outdated: boolean,
    refresh: () => void,
}

const defaultValue = {
    outdated: false,
}


const SnapshotInfoContext = React.createContext<SnapshotInfo>({
    refresh: () => {
    }, ...defaultValue
})

export function useSnapshotInfo(): SnapshotInfo {
    return useContext(SnapshotInfoContext)
}

export function SnapshotInfoProvider(props: React.PropsWithChildren<{}>) {
    const snapshotInfoApi = useSnapshotInfoApi()
    return <SnapshotInfoContext.Provider
        value={{refresh: snapshotInfoApi.refresh, ...snapshotInfoApi.snapshotInfo ?? defaultValue}}>
        {props.children}
    </SnapshotInfoContext.Provider>
}