import React, { useState } from "react";
import { Snapshot } from "../../api/Model";
import { PatchInstance } from "../../api/PublicHooks";
import { useNavigate, useParams } from "react-router-dom";
import { steps } from "../../utils/steps";
import { InstanceRoutes } from "../../utils/routes";
import { useResources } from "../../context/ResourceProvider";
import { Resources } from "../../api/Resources";
import { Tab, Tabs } from "./Tabs";
import { DesktopTable } from "./DesktopTable";
import { MobileTable } from "./MobileTable";
import {
    Box,
    Grid,
    styled, Typography,
    useMediaQuery, useTheme,
} from "@material-ui/core";
import { ButtonLGPrimary } from "../Styleguide/Button";

export function determineNewSmallGridTemplateColumns(activeTab: number): string {
    const newSmallGridTemplateColumns: string[] = Array(5).fill("minmax(0, 1fr)")
    newSmallGridTemplateColumns[activeTab] = 'max-content';
    return newSmallGridTemplateColumns.join(" ");
}

interface Props {
    routes: InstanceRoutes,
    snapshot: Snapshot,
    selectedFunctions: string[],
    patch: (val: Partial<PatchInstance>) => void,
}

export function SelectFunctions({routes, snapshot, selectedFunctions, patch}: Props) {
    const {step} = useParams<{ step: string }>()
    const theme = useTheme();
    const navigate = useNavigate()
    const currentStep = Number(step)
    const [activeTab, setActiveTab] = useState(currentStep);
    const [smallGridTemplateColumns, setSmallGridTemplateColumns] = useState(determineNewSmallGridTemplateColumns(activeTab))
    const showDesktopTable = useMediaQuery(`(min-width:${theme.breakpoints.values.selectionLayout}px)`);

    const updateStep = (newStep: number) => {
        navigate(routes.functions(newStep), {replace: true})
        setActiveTab(newStep);
        setSmallGridTemplateColumns(determineNewSmallGridTemplateColumns(newStep));
    }
    const {resources} = useResources()
    return <>
        <Typography variant={"h1"} style={{ marginTop: 0 }}>
            Auswahl Leistungen &laquo;{steps[currentStep]}&raquo;
        </Typography>
        <Tabs
            value={currentStep}
            activeTab={activeTab}
            smallGridTemplateColumns={smallGridTemplateColumns}
            onChange={(newValue) => updateStep(newValue)}>
            <Tab label={steps[0]}/>
            <Tab label={steps[1]}/>
            <Tab label={steps[2]}/>
            <Tab label={steps[3]}/>
            <Tab label={steps[4]}/>
        </Tabs>

        <Box marginY={2}>
            <Typography variant={"body1"}>
                {resources[`process.step.${currentStep}.description` as keyof Resources]}
            </Typography>
        </Box>

        {showDesktopTable
            ?
            <DesktopTable  currentStep={currentStep} patch={patch} selectedFunctions={selectedFunctions} snapshot={snapshot}/>
            :
            <MobileTable currentStep={currentStep} patch={patch} selectedFunctions={selectedFunctions} snapshot={snapshot}/>
        }
        <StyledGrid item container justifyContent="space-between">
            {currentStep > 0 ?
                <StyledButton variant="contained" color="primary" onClick={() => updateStep(currentStep - 1)}>
                    Zurück zu &laquo;{steps[currentStep - 1]}&raquo;
                </StyledButton> : <div></div>
            }
            {currentStep < 4
                ? <StyledButton variant="contained" color="primary" onClick={() => updateStep(currentStep + 1)}>
                    Weiter zu &laquo;{steps[currentStep + 1]}&raquo;
                </StyledButton>
                : <StyledButton variant="contained" color="primary"
                          onClick={() => navigate(routes.summary)}>
                    Weiter zu &laquo;{stepLabel(1)}&raquo;
                </StyledButton>
            }
        </StyledGrid>
    </>;
}

const StyledGrid = styled(Grid) ({
    paddingTop: '1rem',
    paddingBottom: '1rem',
    gap: 4
})

const StyledButton = styled(ButtonLGPrimary)(({theme}) => ({
    [`@media (max-width:${theme.breakpoints.values.selectionLayout - 1}px)`]: {
        width: "100%",
    },
}))

export function stepLabel(index: number): string {
    switch (index) {
        case 0:
            return "Fachliche Funktionen auswählen"
        case 1:
            return "Empfehlungen"
        default:
            throw new Error(`Unknown Step ${index}`)
    }
}