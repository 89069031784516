import React from "react";
import { Button, styled } from "@material-ui/core";

const StyledButton = styled(Button)({
    fontFamily: "video-react !important",
    height: "43px",
    width: "88px",
    display: "block",
    position: "absolute",
    left: "calc(50% - 40px)",
    top: "calc(50% - 15px)",
    padding: 0,
    cursor: "pointer",
    opacity: 1,
    border: "1px solid #fff",
    backgroundColor: "rgba(43, 51, 63, 0.7)",
    borderRadius: "9px",
    transition: "all 0.4s",
    color: "white",
    boxSizing: "content-box",
    "&:hover, &:focus" :{
        outline: 0,
        borderColor: "#fff",
        backgroundColor: "rgba(115, 133, 159, 0.5)",
        transition: "all 0s",
    }
})

const PlayIcon = styled("div")({
    textAlign: "center",
    fontSize: "19px",
    position: "absolute",
    left: "43%",
    top: "8%"
})

const VideoPlayButton: React.FC = () => {
    return (
        <StyledButton>
            <PlayIcon>
                &#9654;
            </PlayIcon>
        </ StyledButton>
    );
};

export default VideoPlayButton;