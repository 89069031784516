// Keys *MUST NOT* contain spaces
import { steps } from "../utils/steps";

type ResourceType<T> = {
    "process.step.0.description": T,
    "process.step.1.description": T,
    "process.step.2.description": T,
    "process.step.3.description": T,
    "process.step.4.description": T,
}

type ResourceSpecification = {
    defaultValue: string,
    label: string,
}

export const resourceSpec: ResourceType<ResourceSpecification> = {
    "process.step.0.description": {
        defaultValue: "",
        label: `Beschreibung zum Prozessschritt "${steps[0]}"`,
    },
    "process.step.1.description": {
        defaultValue: "",
        label: `Beschreibung zum Prozessschritt "${steps[1]}"`,
    },
    "process.step.2.description": {
        defaultValue: "",
        label: `Beschreibung zum Prozessschritt "${steps[2]}"`,
    },
    "process.step.3.description": {
        defaultValue: "",
        label: `Beschreibung zum Prozessschritt "${steps[3]}"`,
    },
    "process.step.4.description": {
        defaultValue: "",
        label: `Beschreibung zum Prozessschritt "${steps[4]}"`,
    },
}

export type Resources = ResourceType<string>

export function mergeRemoteResources(remoteResources?: Partial<Resources>): Resources {
    const defaultValues = Object.fromEntries(Object.entries(resourceSpec)
        .map(([key, value]) => [key, value.defaultValue])) as Resources
    return {...defaultValues, ...remoteResources}
}

export function toRFFKey(key: string): string {
    if (key.includes(" ")) {
        throw Error("Keys must not contain spaces")
    }
    return key.replaceAll(".", " ")
}

export function fromRFFKey(key: string): string {
    return key.replaceAll(" ", ".")
}
