import React from "react";
import { Backdrop, Box, ClickAwayListener, Icon, IconButton, styled } from "@material-ui/core";
import VideoPlayer from "./VideoPlayer";
import { gutterWidth } from "../../utils/layout";

const StyledBackdrop = styled(Backdrop)({
    color: '#fff',
    zIndex: 999
})

const CloseIcon = styled(IconButton)({
    color: '#fff',
    '&:hover':{
        color: "rgba(255, 255, 255, 0.698)"
    }
})

const IconBox = styled(Box)({
    display: 'flex',
    justifyContent: 'end'
})

export const ContentBox = styled(Box)(({theme}) => ({
    backgroundColor: 'black',
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        maxWidth: `calc(1280px - ${gutterWidth}px - ${gutterWidth}px + 120px)`,
        width: '100%'
    },
    [`@media (min-width:${theme.breakpoints.values.layout}px)`]: {
        width: `calc(100vw - ${gutterWidth}px - ${gutterWidth}px)`
    },
    [`@media (max-width:${theme.breakpoints.values.layout - 1}px)`]: {
        width: `calc(100vw - ${gutterWidth / 2}px - ${gutterWidth / 2}px)`
    }
}))

interface VideoLightBoxProps {
    handleClose: () => void
}

export const VideoLightBox: React.FC<VideoLightBoxProps> = ({ handleClose }) => {

    return (
        <StyledBackdrop open={true}>
            <ClickAwayListener onClickAway={handleClose}>
                <ContentBox>
                    <IconBox>
                        <CloseIcon onClick={handleClose}>
                            <Icon>{"close"}</Icon>
                        </CloseIcon>
                    </IconBox>
                    <VideoPlayer />
                </ContentBox>
            </ClickAwayListener>
        </StyledBackdrop>
    );
}