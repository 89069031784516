import { Icon, styled } from "@material-ui/core";

export const PrimaryIconRound = styled(Icon)(({theme}) => ({
    color: theme.palette.black100.main,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
        color: theme.palette.black80.main,
    },
    "&:focus": {
        borderColor: theme.palette.zueriBlue.main,
        borderWidth: "2px",
        borderRadius: "50%",
        borderStyle: "solid",
    },
    "&:disabled": {
        color: theme.palette.black20.main,
    },
}))
export const DisabledPrimaryIconRound = styled(Icon)(({theme}) => ({
    color: theme.palette.black20.main,
}))