import React, { useEffect, useState } from "react";
import { ProcessInstance } from "../../api/Model";
import { SelectFunctions } from "../../components/SelectFunctions/SelectFunctions";
import { PatchInstance, useProcessInstance } from "../../api/PublicHooks";
import { Summary } from "../../components/Summary/Summary";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { InstanceBar } from "../../components/App/InstanceBar";
import { routes } from "../../utils/routes";
import { patchInstance } from "../../api/patch";
import { PersonalLinkDialog } from "../../components/Dialog/PersonalLinkDialog";

export function EditInstance() {
    const {id} = useParams<{ id: string }>()
    const {instance, patch, upgrade} = useProcessInstance(id!)
    return <>{instance && <EditInstanceLayout instance={instance} patch={patch} upgrade={upgrade}/>}</>
}

interface EditInstanceProps {
    instance: ProcessInstance,
    upgrade: () => void,
    patch: (val: Partial<PatchInstance>) => Promise<void>,
}

function EditInstanceLayout(props: EditInstanceProps) {
    const firstOpened = useLocation().state as boolean | undefined
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(Boolean(firstOpened));
    const [instance, setInstance] = useState(props.instance)

    useEffect(() => {
        setInstance(props.instance)
    }, [props.instance])
    const patch = (val: Partial<PatchInstance>) => {
        props.patch(val)
            .then(() => {
                setInstance(old => patchInstance(old, val))
            })
            .catch(() => {
            })
    }

    return <>
        <PersonalLinkDialog open={openConfirmationDialog} setOpen={setOpenConfirmationDialog}/>
        <InstanceBar outdated={instance.snapshot.outdated}
                     upgrade={props.upgrade}/>
        <Routes>
            <Route path="/functions/:step"
                   element={
                       <SelectFunctions routes={routes.public.instance(instance.id)}
                                        snapshot={instance.snapshot}
                                        selectedFunctions={instance.selectedFunctions}
                                        patch={patch}/>
                   }/>
            <Route path="/summary"
                   element={
                       <Summary instance={instance} editable={true}/>
                   }/>
            <Route path="*" element={<Navigate to={'functions/0'}/>} />
        </Routes>
    </>
}
