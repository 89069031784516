import { styled } from "@material-ui/core";
import { ReactComponent as ZueriLogo } from "../../images/leu.svg";

export const MainLogo = styled(ZueriLogo)(({theme}) => ({
    width: "54px",
    height: "52.06px",
    marginRight: "4px",
    marginTop: "8px",
    marginLeft: "8px",
    [`@media (min-width:${theme.breakpoints.values.xs}px)`]: {
        width: "68px",
        height: "65.73px",
        marginTop: "7px",
    },
    [`@media (min-width:${theme.breakpoints.values.sm}px)`]: {
        width: "84px",
        height: "81px",
        marginRight: "8px",
        marginLeft: "16px",
        marginTop: "5px",
    },
    [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
        width: "123px",
        height: "119px",
        marginRight: "8px",
        marginLeft: "20px",
        marginTop: 0,
    },
    [`@media (min-width:${theme.breakpoints.values.lg}px)`]: {
        width: "140px",
        height: "136px",
        marginRight: "8px",
        marginLeft: "20px",
    },
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        width: "184px",
        height: "179px",
        marginRight: "8px",
        marginLeft: "24px",
    }
}))