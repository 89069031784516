import React from "react";
import { styled, Theme } from "@material-ui/core";

const ButtonText = styled(({isActive, ...rest}) => <div {...rest}/>)(({isActive, theme}: { isActive: boolean, theme: Theme }) => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "block",
    textOverflow: "ellipsis",
    padding: "5px",
    paddingLeft: "6px",
    paddingRight:" 6px",
    [`@media (min-width:${theme.breakpoints.values.selectionButton}px)`]: {
        display: "block",
    },
    [`@media (max-width:${theme.breakpoints.values.selectionButton - 1}px)`]: {
        display: isActive ? "block" : "none",
    },

    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    [`@media (min-width:${theme.breakpoints.values.xs}px)`]: {
        fontSize: "18px",
        lineHeight: "26px",
    },
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        fontSize: "20px",
        lineHeight: "30px",
    },
}))

const NumberText = styled(({isActive, theme, ...rest}) => <div {...rest}/>)(({isActive, theme}: { isActive: boolean, theme: Theme }) => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "block",
    padding: "5px",
    minWidth: "min-content",
    [`@media (min-width:${theme.breakpoints.values.selectionButton}px)`]: {
        display: "none",
    },
    [`@media (max-width:${theme.breakpoints.values.selectionButton - 1}px)`]: {
        display: isActive ? "none" : "block",
    },
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    [`@media (min-width:${theme.breakpoints.values.xs}px)`]: {
        fontSize: "18px",
        lineHeight: "26px",
    },
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        fontSize: "20px",
        lineHeight: "30px",
    },
}))

interface TabProperties {
    className?: string;
    children: React.ReactElement<TabProps>[];
    value: number;
    onChange: (newValue: number) => void;
    activeTab: number;
    smallGridTemplateColumns: string;
}

export function Tabs(props: TabProperties) {

    return <TabContainer className={props.className} smallGridTemplateColumns={props.smallGridTemplateColumns} key={props.smallGridTemplateColumns}>
        {props.children.map((el, i) =>
            <InternalTab className={i === props.value ? 'active' : ''}
                         onClick={() => props.onChange(i)} key={i}>
                <ButtonText isActive={i === props.value}>{el.props.label}</ButtonText>
                <NumberText isActive={i === props.value}>{i + 1}</NumberText>
            </InternalTab>)}
    </TabContainer>
}

interface TabProps {
    label: string;
}

export function Tab(props: TabProps) {
    return null
}

const TabContainer = styled(({smallGridTemplateColumns, ...rest}) => <div {...rest}/>)(({smallGridTemplateColumns, theme}: { smallGridTemplateColumns: string, theme: Theme }) => ({
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    display: 'grid',
    [`@media (min-width:${theme.breakpoints.values.selectFunctionsTab}px)`]: {
        gridTemplateColumns: 'repeat(5, 1fr)'
    },
    [`@media (max-width:${theme.breakpoints.values.selectFunctionsTab - 1}px)`]: {
        gridTemplateColumns: smallGridTemplateColumns
    },
}))

const tabAngle = "15px"
const InternalTab = styled('button')({
    marginLeft: "-6.5px",
    marginRight: "-6.5px",
    border: "none",
    backgroundColor: "#dfdfdf",
    color: "black",
    height: "40px",
    clipPath: `polygon(calc(100% - ${tabAngle}) 0%, 100% 50%, calc(100% - ${tabAngle}) 100%, 0% 100%, ${tabAngle} 50%, 0% 0%)`,
    paddingLeft: "12.5px",
    cursor: "pointer",

    '&:first-child': {
        marginLeft: "0px",
        clipPath: `polygon(0% 0%, calc(100% - ${tabAngle}) 0%, 100% 50%, calc(100% - ${tabAngle}) 100%, 0% 100%)`,
        paddingLeft: "6px",
    },

    '&:last-child': {
        marginRight: "0px",
        clipPath: `polygon(100% 0%, 100% 100%, 0% 100%, ${tabAngle} 50%, 0% 0%)`,
    },

    '&:hover, &.active': {
        backgroundColor: "#8e8e8e",
        color: "white",
    }
})