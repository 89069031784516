import { useCallback, useEffect, useMemo, useState } from "react";
import { BusinessFunction, InstanceOverview, ReferenceProcess, SnapshotInfo } from "./Model";
import axios, { AxiosInstance } from "axios";
import { useSnackbar } from "notistack";
import { Resources } from "./Resources";
import { useLoginActions, useLoginState } from "../context/LoginProvider";
import { useSnapshotInfo } from "../context/SnapshotInfoProvider";

export function useReferenceProcess() {
    const client = useRestClient()
    const [referenceProcess, setReferenceProcess] = useState<ReferenceProcess>()
    const {loggedIn} = useLoginState()

    const refresh = useCallback(() => {
        if (!loggedIn) {
            return
        }
        const source = axios.CancelToken.source()
        client.get<ReferenceProcess>("/reference-process", {
            cancelToken: source.token
        })
            .then(response => setReferenceProcess(response.data))
            .catch(noop)
        return () => source.cancel()
    }, [loggedIn, setReferenceProcess, client])

    useEffect(() => {
        return refresh()
    }, [refresh, loggedIn])

    return {referenceProcess, refresh}
}

export function useBusinessFunction(id: string) {
    const client = useRestClient()
    const [businessFunction, setBusinessFunction] = useState<BusinessFunction>()
    const {loggedIn} = useLoginState()

    useEffect(() => {
        if (!loggedIn) {
            return
        }

        const source = axios.CancelToken.source()
        client.get<BusinessFunction>(`/functions/${id}`, {
            cancelToken: source.token
        })
            .then(response => setBusinessFunction(response.data))
            .catch(noop)
        return () => source.cancel()
    }, [id, client, loggedIn])

    return {businessFunction}
}

export function useFunctionApi() {
    const client = useRestClient()
    const refreshSnapshotInfo = useSnapshotInfo().refresh
    const snackbar = useSnackbar();

    const create = (fun: Partial<BusinessFunction>) => {
        return client.post<BusinessFunction>("/functions", fun)
            .then(response => {
                snackbar.enqueueSnackbar("Funktion wurde erstellt", {variant: "success"})
                refreshSnapshotInfo()
                return response.data
            })
            .catch(error => {
                snackbar.enqueueSnackbar("Ein Fehler ist aufgetreten", {variant: "error"})
                return Promise.reject(error)
            })
    }

    const update = (fun: Partial<BusinessFunction>) => {
        return client.put(`/functions/${fun.id}`, fun)
            .then(response => {
                snackbar.enqueueSnackbar("Funktion wurde aktualisiert", {variant: "success"})
                refreshSnapshotInfo()
                return response.data
            })
            .catch(error => {
                snackbar.enqueueSnackbar("Ein Fehler ist aufgetreten", {variant: "error"})
                return Promise.reject(error)
            })
    }

    const updateOrder = (step: number, order: string[]) => {
        return client.put(`/functions/order`, {step, order})
            .then(refreshSnapshotInfo)
            .then(() => {
                snackbar.enqueueSnackbar("Speichern erfolgreich", {variant: "success"})
            })
            .catch(error => {
                snackbar.enqueueSnackbar("Ein Fehler ist aufgetreten", {variant: "error"})
                return Promise.reject(error)
            })
    }

    const remove = (fun: Partial<BusinessFunction>) => {
        return client.delete(`/functions/${fun.id}`)
            .then(refreshSnapshotInfo)
            .then(() => {
                snackbar.enqueueSnackbar("Funktion wurde gelöscht", {variant: "success"})
            })
            .catch(error => {
                snackbar.enqueueSnackbar("Ein Fehler ist aufgetreten", {variant: "error"})
                return Promise.reject(error)
            })
    }

    return {create, update, updateOrder, remove}
}

export function useInstanceAdminApi() {
    const client = useRestClient()
    const snackbar = useSnackbar();

    const remove = (instance: InstanceOverview) => {
        return client.delete(`/instances/${instance.id}`)
            .then(response => {
                snackbar.enqueueSnackbar("Instanz wurde gelöscht", {variant: "success"})
            })
            .catch(() => {
                snackbar.enqueueSnackbar("Fehler beim Löschen", {variant: "error"})
                return Promise.reject()
            })
    }
    return {remove}
}

export function useSnapshotInfoApi() {
    const client = useRestClient()
    const [snapshotInfo, setSnapshotInfo] = useState<SnapshotInfo>()
    const [dummy, setDummy] = useState({})
    const refresh = () => setDummy({})
    const {loggedIn} = useLoginState()

    useEffect(() => {
        if (!loggedIn) {
            return;
        }

        const source = axios.CancelToken.source()
        client.get<SnapshotInfo>(`/snapshot-info`, {
            cancelToken: source.token
        })
            .then(response => setSnapshotInfo(response.data))
            .catch(noop)
        return () => source.cancel()
    }, [client, dummy, loggedIn])

    return {snapshotInfo, refresh}
}

export function useResourceApi() {
    const client = useRestClient()
    const snackbar = useSnackbar()
    const update = (resources: Partial<Resources>) => {
        return client.patch('/resources', resources)
            .then(() => {
                snackbar.enqueueSnackbar("Textbausteine wurden gespeichert", {variant: "success"})
            })
    }
    return {update}
}

export function useAdminApi() {
    const client = useRestClient()
    const snackbar = useSnackbar();
    const refreshSnapshotInfo = useSnapshotInfo().refresh

    const resetRateLimits = () => {
        return client.delete<void>("/admin/rate-limits")
            .then(() => {
                snackbar.enqueueSnackbar("Zugriffsbeschränkungen wurden zurückgesetzt", {variant: "success"})
            })
            .catch(error => {
                snackbar.enqueueSnackbar("Ein Fehler ist aufgetreten", {variant: "error"})
                return Promise.reject(error)
            })
    }

    const publishReferenceProcess = () => {
        return client.post<void>("/snapshots")
            .then(() => {
                snackbar.enqueueSnackbar("Eine neue Version wurde veröffentlicht", {variant: "success"})
                refreshSnapshotInfo()
            })
            .catch(error => {
                snackbar.enqueueSnackbar("Ein Fehler ist aufgetreten", {variant: "error"})
                return Promise.reject(error)
            })
    }

    return {resetRateLimits, publishReferenceProcess}
}

function useRestClient(): AxiosInstance {
    const {logout} = useLoginActions()
    return useMemo(() => {
        const client = axios.create({baseURL: "/api/v1/private", headers: {"X-Requested-With": "XMLHttpRequest"}});
        client.interceptors.response.use(response => {
            return response
        }, error => {
            if (error.response && error.response.status === 401) {
                logout()
            }
            return Promise.reject(error)
        })
        return client
    }, [logout]);
}

export function defaultErrorHandler(error: any) {
    // Ignore
}

const noop = () => {
}