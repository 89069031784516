import React from "react";
import { EGovNavigatorHeader } from "../../components/App/EGovNavigatorHeader";
import { FormContainer } from "../../components/Containers";

export function NotAvailable() {
    return <>
        <EGovNavigatorHeader/>
        <FormContainer>
            Aktuell kann leider kein digitales Vorhaben oder Projekt anhand des Referenzprozesses geplant werden.
        </FormContainer>
    </>
}