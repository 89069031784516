import React from 'react';
import { BigPlayButton, Player } from 'video-react';
import { Box, styled } from "@material-ui/core";

export const StyledPlayer = styled(Player)({
    maxHeight: 'inherit',
    paddingTop: '0 !important',
    height: '100% !important',
    '& > .video-react-video': {
        maxHeight: 'inherit',
    }
})

const VideoplayerBox = styled(Box)(({theme}) => ({
    height: '100%',
    width: 'auto !important',
    aspectRatio: '16/9',
    [`@media (max-width: ${theme.breakpoints.values.xl - 1}px)`]: {
        paddingLeft: "32px !important",
        paddingRight: "32px !important",
        paddingBottom: "32px !important",
    },
    [`@media (max-width: ${theme.breakpoints.values.md - 1}px)`]: {
        paddingLeft: "24px !important",
        paddingRight: "24px !important",
        paddingBottom: "24px !important",
    },
    [`@media (max-width: ${theme.breakpoints.values.xs - 1}px)`]: {
        paddingLeft: "20px !important",
        paddingRight: "20px !important",
        paddingBottom: "20px !important",
    },
    [`@media (min-width: ${theme.breakpoints.values.xl}px)`]: {
        paddingLeft: "40px !important",
        paddingRight: "40px !important",
        paddingBottom: "40px !important",
    }
}))

const VideoPlayer: React.FC = () => {

    return (
        <VideoplayerBox>
            <StyledPlayer
                playsInline={true}
                src={'241028_StaatskanzleiKtZH-Referenzprozess lauter.mp4'}
            >
                <BigPlayButton position="center" />
            </StyledPlayer>
        </VideoplayerBox>
    );
};

export default VideoPlayer;
