import React, { useEffect } from 'react';
import { Box, Container, styled, Theme } from "@material-ui/core";
import { BrowserRouter as Router, Route, useLocation, Routes } from 'react-router-dom';
import { LoginProvider, useLoginState } from "./context/LoginProvider";
import { EditInstance } from "./pages/public/EditInstance";
import { AdminAppBar, PublicAppBar } from "./components/App/AppBars";
import { AdminThemeProvider, PublicThemeProvider } from "./context/ThemeProviders";
import { SnackbarProvider } from "notistack";
import { AdminFooter, PublicFooter } from "./components/App/Footers";
import { appBarHeight, coordinatesHeight, footerHeight, gutterWidth, tabBarHeight } from "./utils/layout";
import { Impressum } from "./pages/public/Impressum";
import { Admin } from "./pages/admin/Admin";
import { SnapshotInfoProvider } from "./context/SnapshotInfoProvider";
import { ResourceProvider } from "./context/ResourceProvider";
import { Login } from "./pages/admin/Login";
import { LandingPage } from "./pages/public/LandingPage";
import { NotAvailable } from "./pages/public/NotAvailable";
import { Sharing } from "./pages/public/Sharing";

function App() {
    return (
        <LoginProvider>
            <ResourceProvider>
                <SnackbarProvider autoHideDuration={1000}
                                  anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                  }}>
                    <Router>
                        <ScrollToTop/>
                        <Routes>
                            <Route path="/admin/*" element={<AdminApp/>}/>
                            <Route path="/*" element={<PublicApp/>}/>
                        </Routes>
                    </Router>
                </SnackbarProvider>
            </ResourceProvider>
        </LoginProvider>
    )
}

export default App;

function AdminApp() {
    const {loggedIn} = useLoginState()
    return (
        <SnapshotInfoProvider>
            <AdminThemeProvider>
                <Login/>
                <Box height="100%" hidden={!loggedIn}>
                    <AdminAppBar/>
                    <AdminBody/>
                    <AdminFooter/>
                </Box>
            </AdminThemeProvider>
        </SnapshotInfoProvider>
    )
}

function PublicApp() {

    return (
        <PublicThemeProvider>
            <PublicAppBar/>
            <PublicBody/>
            <PublicFooter/>
        </PublicThemeProvider>
    )
}

function PublicBody() {
    return (
        <StyledContainer>
            <Routes>
                <Route path="/instances/:id/*" element={<EditInstance/>}/>
                <Route path="/sharing/:id" element={<Sharing/>}/>
                <Route path="/impressum" element={<Impressum/>}/>
                <Route path="/not-available" element={<NotAvailable/>}/>
                <Route path="/" element={<LandingPage/>}/>
            </Routes>
        </StyledContainer>
    )
}

function AdminBody() {
    return (
        <StyledContainer isAdmin>
            <Admin/>
        </StyledContainer>
    )
}

const StyledContainer = styled(({isAdmin, ...rest}) => <Container maxWidth={'xl'} {...rest}/>)(({theme, isAdmin}: {theme: Theme, isAdmin?: boolean}) => ({
    backgroundColor: '#fff',
    minHeight: Boolean(isAdmin)
        ? `calc(100% - ${appBarHeight} - ${tabBarHeight} - ${footerHeight})`
        : `calc(100% - ${appBarHeight} - ${footerHeight} - ${coordinatesHeight})`,
    paddingBottom: Boolean(isAdmin) ? footerHeight : 0,
    '@media print': {
        padding: 0
    },
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width:${theme.breakpoints.values.layout}px)`]: {
        paddingLeft: gutterWidth,
        paddingRight: gutterWidth,
    },
    [`@media (max-width:${theme.breakpoints.values.layout - 1}px)`]: {
        paddingLeft: gutterWidth / 2,
        paddingRight: gutterWidth / 2,
    }
}))

function ScrollToTop() {
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}