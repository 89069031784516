import { ListItem, styled } from "@material-ui/core";

export const StyledListItem = styled(ListItem)(({theme}) => ({
    marginTop: "4px",
    marginBottom: "12px",
    padding: 0,
    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {
        marginTop: "8px",
        marginBottom: "16px",
    },
}))