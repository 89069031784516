import { Summary } from "../../components/Summary/Summary";
import { useProcessInstanceViaReadonly } from "../../api/PublicHooks";
import { NotAvailable } from "./NotAvailable";
import { useParams } from "react-router-dom";
import { InstanceBar } from "../../components/App/InstanceBar";
import React from "react";
import { noop } from "lodash";

export function Sharing() {
    const {id} = useParams<{ id: string }>()

    if (id === undefined) {
        return <NotAvailable />
    } else {
        return <SharingLoader id={id}/>
    }
}

interface Props {
    id: string,
}

function SharingLoader({id}: Props) {
    const {instance} = useProcessInstanceViaReadonly(id)

    if (instance === undefined) {
        return <NotAvailable />
    } else {
        return (
            <>
                <InstanceBar outdated={false}
                             upgrade={noop}/>
                <Summary instance={instance} editable={false}/>
            </>
        )
    }
}