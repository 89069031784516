import { Button, styled } from "@material-ui/core";

const ButtonSecondary = styled(Button)(({theme}) => ({
    backgroundColor: theme.palette.black10.main,
    color: theme.palette.black60.main,
    borderRadius: "2px",

    "&:hover": {
        backgroundColor: theme.palette.black20.main,
        color: theme.palette.black100.main,
    },
    "&:focus": {
        backgroundColor: theme.palette.black10.main,
        color: theme.palette.black60.main,
        borderColor: theme.palette.zueriBlue.main,
        borderWidth: "2px",
        borderStyle: "solid",
    },
    "&:active": {
        backgroundColor: theme.palette.black100.main,
        color: theme.palette.white100.main,
    },
    "&:disabled": {
        backgroundColor: theme.palette.black5.main,
        color: theme.palette.black20.main,
    },

    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    "@media (min-width: 400px)": {
        fontSize: "18px",
        lineHeight: "26px",
    },
    "@media (min-width: 1280px)": {
        fontSize: "20px",
        lineHeight: "30px",
    },
}))
export const ButtonLGSecondary = styled(ButtonSecondary)({
    height: "48px",
    minWidth: "48px",
})
const ButtonPrimary = styled(Button)(({theme}) => ({
    backgroundColor: theme.palette.black100.main,
    color: theme.palette.white100.main,
    borderRadius: "2px",
    boxSizing: "border-box",

    "&:hover": {
        backgroundColor: theme.palette.black80.main,
        color: theme.palette.white100.main,
    },
    "&:focus": {
        borderColor: theme.palette.zueriBlue.main,
        borderWidth: "2px",
        borderStyle: "solid",
    },
    "&:disabled": {
        backgroundColor: theme.palette.black20.main,
        color: theme.palette.white100.main,
    },

    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    "@media (min-width: 400px)": {
        fontSize: "18px",
        lineHeight: "26px",
    },
    "@media (min-width: 1280px)": {
        fontSize: "20px",
        lineHeight: "30px",
    },
}))
export const ButtonLGPrimary = styled(ButtonPrimary)({
    height: "48px",
    minWidth: "48px",
})