import React from "react";
import { Box, styled, Theme, Typography } from "@material-ui/core";
import { FunctionData } from "../../api/Model";

const spacing = 0.75

interface FunctionRecommendationProps {
    functionData: FunctionData,
}

export function FunctionRecommendation(props: FunctionRecommendationProps) {
    const showRecommendation = (props.functionData.name !== null && props.functionData.name.length > 0)
    const recommendation = (props.functionData.recommendation !== null && props.functionData.recommendation.length > 0) ? props.functionData.recommendation : ''

    return (
        <RecommendationBox>
            <Typography variant={"body1"} style={{ marginBottom: 0 }}>
                {showRecommendation && recommendation}
            </Typography>
        </RecommendationBox>
    )
}

const RecommendationBox = styled(Box)((props: { theme: Theme } ) => ({
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    textAlign: "left",
    padding: props.theme.spacing(spacing, spacing),
    paddingBottom: "0px !important",
    wordBreak: "break-word"
}))
